import * as React from "react";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";

const getLabel = (pathname: string) => {
  if (pathname == "/") return "Sales";

  if (pathname == "/ar") return "A/R";

  if (pathname == "/companies") return "Companies";

  if (pathname == "/products") return "Products";

  if (pathname.startsWith("/companies"))
    return "Company " + _.last(pathname.split("/"));

  if (pathname.startsWith("/customers/search"))
    return _.last(pathname.split("/"));

  if (pathname.startsWith("/customers/")) return _.last(pathname.split("/"));

  return null;
};

const breadcrumb = (props: RouteComponentProps) => (
  <Typography variant="h6" style={{ width: 180 }}>
    {getLabel(props.location.pathname)}
  </Typography>
);

export default withRouter(React.memo(breadcrumb));
