import { AxiosRequestConfig } from "axios";

import * as authService from "../AuthService";

const intercept = async (req: AxiosRequestConfig) => {
  const token = await authService.token();
  if (!token) return req;

  req.headers = { Authorization: "Bearer " + token };

  return req;
};

export default intercept;
