import React from "react";

import { UserSettings } from "../services/AuthService";
import * as authService from "../services/AuthService";

export type Action = {
  type: "setUser";
  payload: {
    user?: UserSettings;
  };
};

export const UserContext = React.createContext<{
  user: UserSettings;
  dispatch: React.Dispatch<Action>;
}>({
  user: null,
  dispatch: null
});

const reducer = (state: UserSettings, action: Action) => {
  switch (action.type) {
    case "setUser":
      return action.payload.user;
    default:
      return state;
  }
};

const initialState: UserSettings = null;

const userContextProvider = (props: any) => {
  const [user, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const onLogin = (user: authService.UserSettings) =>
      dispatch({ type: "setUser", payload: { user } });
    const onLogout = () =>
      dispatch({ type: "setUser", payload: { user: null } });
    authService.events.on("login", onLogin);
    authService.events.on("logout", onLogout);

    return () => {
      authService.events.off("login", onLogin);
      authService.events.off("logout", onLogout);
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        dispatch
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default React.memo(userContextProvider);
