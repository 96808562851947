import "core-js";
import "regenerator-runtime";
require("raf/polyfill"); // polyfill

import { hot } from "react-hot-loader/root";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

const HotApp = hot(App);
//import App from "./App";

ReactDOM.render(<HotApp />, document.getElementById("root"));

// javascript is working, we hide the initial page loader
document.getElementById("pageloader").style.display = "none";

console.log(`Starting version ${VERSION}`);
