export const cryptNumber = (number: number) =>
  btoa(randomStr() + "|" + number * 9432115781);

export const randomStr = (length = 10, number = false) =>
  number
    ? shuffle("0123456789").substring(0, length)
    : shuffle(
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      ).substring(0, length);

function shuffle(string: string) {
  const parts = string.split("");

  for (let i = parts.length; i > 0; ) {
    const random = Math.random() * i;
    const temp = parts[--i];
    parts[i] = parts[random];
    parts[random] = temp;
  }

  return parts.join("");
}

export const invoiceUrl = (
  id: number,
  mode: "default" | "be_usd" | "be_eur" = "default"
) => {
  let base: string;

  switch (mode) {
    case "default":
      base = "http://www.geopostcodes.com/pdf/invoice.php?id=";
      break;
    case "be_eur":
      base = "http://www.geopostcodes.com/pdf/invoice_be.php?id=";
      break;
    case "be_usd":
      base = "http://www.geopostcodes.com/pdf/invoice_be_usd.php?id=";
      break;
    default:
      base = "http://www.geopostcodes.com/pdf/invoice.php?id=";
      break;
  }

  return base + cryptNumber(id) + "&show=true";
};
