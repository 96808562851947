import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import AppSearchInput from "./components/search/AppSearchInput";
import Breadcrumb from "./components/Breadcrumb";
import AppButtons from "./components/AppButtons";
import * as util from "./services/Utils";
import Login from "./pages/login";
import withPrivateRoute from "./hoc/withPrivateRoute";
import UserContextProvider from "./components/UserContextProvider";

const LazySales = React.lazy(() =>
  import(/* webpackChunkName: "sales" */ "./pages/sales")
);

const LazySearch = React.lazy(() =>
  import(/* webpackChunkName: "search" */ "./pages/search")
);

const LazyCustomer = React.lazy(() =>
  import(/* webpackChunkName: "customer" */ "./pages/customer")
);

const LazyCompanies = React.lazy(() =>
  import(/* webpackChunkName: "companies" */ "./pages/companies")
);

const LazyCompany = React.lazy(() =>
  import(/* webpackChunkName: "company" */ "./pages/company")
);

const LazyAccountsReceivables = React.lazy(() =>
  import(/* webpackChunkName: "collection" */ "./pages/accounts_receivables")
);

const LazyProducts = React.lazy(() =>
  import(/* webpackChunkName: "products" */ "./pages/products")
);

const theme = createMuiTheme({
  palette: {
    primary: { main: util.primary },
    secondary: { main: util.secondary }
  }
});

function ElevationScroll(props: any) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

const app = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <Router>
          <CssBaseline />
          <ElevationScroll>
            <AppBar position="fixed">
              <Toolbar>
                <Breadcrumb />
                <AppButtons />
                <div style={{ flexGrow: 1 }} />
                <AppSearchInput />
              </Toolbar>
            </AppBar>
          </ElevationScroll>
          <Container maxWidth={false} style={{ marginTop: 75 }}>
            <React.Suspense fallback={<em>Loading...</em>}>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={withPrivateRoute(LazySales, Login)}
                />
                <Route
                  exact
                  path="/ar"
                  component={withPrivateRoute(LazyAccountsReceivables, Login)}
                />
                <Route
                  exact
                  path="/customers/:id"
                  component={withPrivateRoute(LazyCustomer, Login)}
                />
                <Route
                  exact
                  path="/customers/search/:value"
                  component={withPrivateRoute(LazySearch, Login)}
                />
                <Route
                  exact
                  path="/companies/:id"
                  component={withPrivateRoute(LazyCompany, Login)}
                />
                <Route
                  exact
                  path="/companies"
                  component={withPrivateRoute(LazyCompanies, Login)}
                />
                <Route
                  exact
                  path="/products"
                  component={withPrivateRoute(LazyProducts, Login)}
                />
              </Switch>
            </React.Suspense>
          </Container>
        </Router>
      </UserContextProvider>
    </ThemeProvider>
  );
};
export default React.memo(app);
