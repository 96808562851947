import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

import InvoiceLink from "../invoices/InvoiceLink";
import * as utils from "../../services/Utils";
import CompanyResult from "./CompanyResult";
import * as toolsPhp from "../../services/ToolsPhp";
import * as companies from "../../services/Companies";

const searchResults = (
  props: RouteComponentProps & {
    companiesData: companies.SearchResult[];
    invoicesData: {
      type: "invoice" | "company";
      invoice: number;
      currency: "USD" | "EUR";
      date: string;
      mainId: number;
      company: string;
    }[];
    onClick: () => void;
  }
) => {
  const [selected, setSelected] = React.useState<number>(0);
  const length = props.companiesData
    ? props.companiesData.length
    : props.invoicesData.length;

  const onClickCompany = (mainId: number) => {
    props.history.push("/companies/" + mainId);
    props.onClick();
  };

  const moveUp = () => {
    if (selected === null || selected === 0) setSelected(length - 1);
    else setSelected(selected - 1);
  };

  const moveDown = () => {
    if (selected === null || selected === Math.min(9, length - 1))
      setSelected(0);
    else setSelected(selected + 1);
  };

  const onKeyPressed = (e: any) => {
    switch (e.keyCode) {
      case 38:
        moveUp();
        break;

      case 40:
        moveDown();
        break;

      case 13:
        if (selected !== null) {
          if (props.companiesData)
            onClickCompany(props.companiesData[selected].mainId);
          else {
            const data = props.invoicesData[selected];
            if (data.type === "invoice") {
              window.open(toolsPhp.invoiceUrl(data.invoice), "_blank");
              props.onClick();
            } else onClickCompany(data.mainId);
          }
        }
        break;
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", onKeyPressed);

    // Specify how to clean up after this effect:
    return () => document.removeEventListener("keydown", onKeyPressed);
  });

  return (
    <MenuList>
      {props.invoicesData &&
        props.invoicesData.slice(0, 10).map((result, index) => {
          if (result.type === "invoice") {
            let content = (
              <InvoiceLink
                id={result.invoice}
                justify="flex-start"
                type={utils.getInvoiceType(
                  new Date(result.date),
                  result.currency
                )}
              />
            );
            if (selected === index) content = <b>{content}</b>;

            return <MenuItem key={index}>{content}</MenuItem>;
          } else
            return (
              <CompanyResult
                key={result.mainId}
                result={result}
                selected={selected === index}
                onClick={() => onClickCompany(result.mainId)}
              />
            );
        })}
      {props.companiesData &&
        props.companiesData
          .slice(0, 10)
          .map((result, index) => (
            <CompanyResult
              key={result.mainId}
              result={result}
              selected={selected === index}
              onClick={() => onClickCompany(result.mainId)}
            />
          ))}
      {length > 10 && <MenuItem disabled>{length - 10} more exists…</MenuItem>}
    </MenuList>
  );
};

export default withRouter(React.memo(searchResults));
