import * as React from "react";

const Date = ({ value }: { value: Date }) => (
  <>
    {value
      .getHours()
      .toString()
      .padStart(2, "0") +
      ":" +
      value
        .getMinutes()
        .toString()
        .padStart(2, "0") +
      ":" +
      value
        .getSeconds()
        .toString()
        .padStart(2, "0")}
  </>
);

export default React.memo(Date);
