import * as React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import TypedSnackbar from "../components/TypedSnackbar";

const updateButton = ({
  dirtyData,
  updateData,
  disabled,
  labels = {
    button: "Update",
    success: "The data has been updated",
    failure: "Error updating the data"
  },
  captureEnter
}: {
  dirtyData: any;
  updateData: () => Promise<boolean | void>;
  disabled?: boolean;
  labels?: {
    button: string;
    success: string;
    failure: string;
  };
  /** Capture the ENTER key */
  captureEnter?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState<"success" | "error">("success");
  const [processing, setProcessing] = React.useState(false);

  const disallowed = processing || !dirtyData || disabled;

  const onClick = async () => {
    setProcessing(true);

    const result = await updateData();
    setVariant(result === undefined || result ? "success" : "error");

    setOpen(true);
    setProcessing(false);
  };

  React.useEffect(() => {
    if (!captureEnter) return;
    const listener = (event: KeyboardEvent) => {
      if (disallowed) return;
      if (event.code === "Enter" || event.code === "NumpadEnter") onClick();
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [dirtyData]);

  return (
    <>
      <Button
        size="small"
        color="primary"
        disabled={disallowed}
        onClick={onClick}
      >
        {labels.button}{" "}
        {processing && (
          <CircularProgress size={20} style={{ marginLeft: 20 }} />
        )}
      </Button>
      <TypedSnackbar
        variant={variant}
        message={variant === "success" ? labels.success : labels.failure}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default React.memo(updateButton);
