import axios from "axios";
import * as _ from "lodash";

import CryptoInterceptor from "./interceptors/crypto.interceptor";
import AuthInterceptor from "./interceptors/auth.interceptor";
import ErrorInterceptor from "./interceptors/error.interceptor";

// initialization code
const hostname = _.get(window, "location.hostname");
//const host = _.get(window, "location.host");
const protocol = _.get(window, "location.protocol", "http:");

export let geosBackend: string;
// define APIs hostname
if (hostname === "localhost" || hostname === "127.0.0.1")
  geosBackend = protocol + "//" + hostname + ":8080/";
else geosBackend = "https://backend.geopostcodes.com/";

// create Axios instances
// default
export const api = axios.create({
  baseURL: geosBackend
});

api.interceptors.request.use(CryptoInterceptor);
api.interceptors.request.use(AuthInterceptor, ErrorInterceptor);
api.interceptors.response.use(response => response, ErrorInterceptor);
