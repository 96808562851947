//import GeosAuthService from "../GeosAuthService";
import axios, { AxiosError } from "axios";

export default function intercept(error: AxiosError) {
  //var regexp = /\/errors$/;

  if (axios.isCancel(error)) {
    // cancelled request, nothing to do
  } //else if (error.code === "ECONNABORTED")
    /*GeosStore.setSnackbar(
      "The request took too much time. Please simplify or retry later.",
      "error"
    );*/
  //else if (error.response.status === 403) {
    // causes: on the server side, the token has expired or the memcached server was restarted
    //if (GeosAuthService.authenticated) GeosAuthService.logout();
    //GeosStore.setSnackbar('Your session has expired, please login again.', 'error');
  //} else if (!regexp.test(error.request.responseURL))
    // avoid infinite loop
    //GeosStore.setSnackbar(error, "error");

  return Promise.reject(error);
}
