import React from "react";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";

import * as sales from "../services/Sales";
import withData, {
  SpinnerPreference,
  NoDataFoundPreference
} from "../hoc/withData";
import { UserContext } from "./UserContextProvider";
import * as authService from "../services/AuthService";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  badge: {
    margin: theme.spacing(1)
  }
}));

interface PropsItem {
  icon: string;
  label: string;
  path?: string;
  url?: string;
  number?: number;
  onClick?: () => void;
}

const itemBase = (props: RouteComponentProps & PropsItem) => {
  const classes = useStyles({});
  const { icon, label, path, number, url, onClick } = props;

  return (
    <Button
      color="inherit"
      disabled={props.location.pathname === path}
      onClick={() => {
        if (path) props.history.push(path);
        if (url) window.open(url, "_blank");
        if (onClick) onClick();
      }}
      className={classes.button}
    >
      {label}
      <Badge color="secondary" badgeContent={number}>
        <Icon className={classes.rightIcon}>{icon}</Icon>
      </Badge>
    </Button>
  );
};

const Item = React.memo(withRouter(itemBase));

const appButtons = ({
  data,
  fetchData
}: {
  data: [];
  fetchData: () => Promise<any>;
}) => {
  const classes = useStyles({});

  return (
    <>
      <Item label="Sales" icon="trending_up" path="/" />
      <Item label="Companies" icon="domain" path="/companies" />
      <Item
        label="A/R"
        number={_.get(data, "length")}
        icon="money_off"
        path="/ar"
        onClick={() => fetchData()}
      />
      <Item
        label="Quotes"
        icon="monetization_on"
        url="http://www.geopostcodes.com/admin/?pg=devis"
      />
      <Item label="Products" icon="shopping_cart" path="/products" />
      <UserContext.Consumer>
        {({ user }) => {
          if (!user) return null;
          return (
            <Tooltip title="Logout">
              <Button
                color="inherit"
                className={classes.button}
                onClick={() => authService.signOut()}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
              </Button>
            </Tooltip>
          );
        }}
      </UserContext.Consumer>
    </>
  );
};

export default React.memo(
  withData<null, []>(appButtons, sales.pending, {
    spinner: SpinnerPreference.Never,
    noDataFound: NoDataFoundPreference.NoAction
  })
);
