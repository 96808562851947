import { api } from "./Api";

const url = "/customer-portal/user";

export interface UserNew {
  uid: string;
  email: string;
}

export interface User extends UserNew {
  roleCode:
    | "gpc_admin"
    | "standard_user"
    | "customer_admin"
    | "purchase_responsible";
  verified: boolean;
  parameters: any;
}

const getUserFromData = (data: any): User => ({
  uid: data.uid,
  email: data.email,
  roleCode: data.role_code,
  verified: data.verified,
  parameters: data.parameters
});

const urlUid = (uid: string, additionalPath: string = null) =>
  url + "/" + uid + (additionalPath ? "/" + additionalPath : "");

export const getUser = (uid: string) =>
  api.get(urlUid(uid)).then(res => getUserFromData(res.data));

export const createUser = (user: UserNew) =>
  api.post(url, user).then(res => getUserFromData(res.data));

export const updateLogin = (uid: string, verified: boolean) =>
  api
    .post(urlUid(uid, "login"), {
      verified
    })
    .then(res => res.data);

export const setParameters = (uid: string, parameters: any) =>
  api.post(urlUid(uid, "parameters"), parameters).then(res => res.data);
