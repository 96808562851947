import * as React from "react";

import { UserContext } from "../components/UserContextProvider";

function withPrivateRoute(
  /** Protected component */
  WrappedComponent: React.ComponentType,
  /** Component displayed when no user is logged in */
  UnauthorizedComponent: React.ComponentType
) {
  const wrapper = (props: JSX.IntrinsicAttributes) => (
    <UserContext.Consumer>
      {({ user }) => (
        <>
          {!user && <UnauthorizedComponent {...props} />}
          {user && <WrappedComponent {...props} />}
        </>
      )}
    </UserContext.Consumer>
  );

  return React.memo(wrapper);
}

export default withPrivateRoute;
