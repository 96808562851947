import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import UpdateButton from "../components/UpdateButton";
import * as authService from "../services/AuthService";

const login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item style={{ paddingTop: 100 }}>
        <Paper>
          <form noValidate autoComplete="off" style={{ padding: 20 }}>
            <Typography
              variant="h4"
              style={{ textAlign: "center", paddingBottom: 20 }}
            >
              Login
            </Typography>
            <TextField
              label="Email"
              autoFocus={true}
              value={email}
              margin="normal"
              fullWidth={true}
              onChange={event => {
                const value = event.target.value;
                setEmail(value);
              }}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              margin="normal"
              fullWidth={true}
              onChange={event => {
                const value = event.target.value;
                setPassword(value);
              }}
            />
            <div style={{ paddingTop: 20 }} />
            <UpdateButton
              dirtyData={email && password}
              labels={{
                button: "Login",
                success: "Login successful",
                failure: "Invalid login or password"
              }}
              updateData={async () => await authService.signIn(email, password)}
              captureEnter={true}
            />
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default React.memo(login);
