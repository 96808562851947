import { api } from "./Api";

const url = "/admin/invoices"; // URL to web api

export const search = (number: string) =>
  api.get(url, { params: { number } }).then((res) => res.data);

export const status = (invoice: string, enable: boolean) =>
  api
    .get(url + "/" + invoice + "/status", { params: { enable } })
    .then((res) => res.data);

export const abandon = (invoice: string) =>
  api.delete(url + "/" + invoice).then((res) => res.data);

export const del = (invoice: string) =>
  api
    .delete(url + "/" + invoice, { params: { hard: true } })
    .then((res) => res.data);

export const renew = (invoice: string) =>
  api.get(url + "/" + invoice + "/renew").then((res) => res.data);

export const collect = (
  invoice: number,
  method: string,
  amount: number,
  transactionId: string,
  changeDate: boolean,
  download: boolean
) =>
  api.post(url + "/" + invoice + "/collect", {
    method,
    montant: amount,
    transid: transactionId,
    changeDate,
    download,
  });

export const getEditData = (invoice: number) =>
  api.get(url + "/" + invoice + "/edit").then((res) => res.data);

export const setEditData = (invoice: number, po: string, comments: string) =>
  api
    .post(url + "/" + invoice + "/edit", { po, comments })
    .then((res) => res.data);
