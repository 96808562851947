import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

const styledSpinner = (
  {
    style = "linear"
  }: {
    style?: "linear" | "circular";
  } = { style: "linear" }
) => {
  const classes = useStyles({});
  return style === "circular" ? (
    <CircularProgress className={classes.progress} />
  ) : (
    <LinearProgress className={classes.progress} />
  );
};

export default React.memo(styledSpinner);
