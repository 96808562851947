import * as React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import * as companies from "../../services/Companies";

const companyResult = ({
    result,
    selected,
    onClick
  }: {
    result: companies.SearchResult;
    selected: boolean;
    onClick: () => void;
  }) => {
    let text: string | JSX.Element = result.company;
    if (text.length > 25 && text.includes(" ")) {
      // trim the string to the maximum length
      text = text.substr(0, 25);
  
      // re-trim if we are in the middle of a word
      text = text.substr(0, Math.min(text.length, text.lastIndexOf(" ")));
      text = (
        <div>
          {text}
          <Typography color="textSecondary" component="span">
            …
          </Typography>
        </div>
      );
    }
  
    if (selected) text = <b>{text}</b>;
  
    return <MenuItem onClick={() => onClick()}>{text}</MenuItem>;
  };
  
  export default React.memo(companyResult);