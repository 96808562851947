import * as EventEmitter from "eventemitter3";
import * as firebase from "firebase";

import * as CustomerPortalService from "./CustomerPortalService";

export const events: EventEmitter = new EventEmitter();

export interface UserSettings {
  id: string;
  login: string;
  role:
    | "gpc_admin"
    | "standard_user"
    | "customer_admin"
    | "purchase_responsible";
  stayConnected?: boolean;
}

export const blankUserSettings: UserSettings = {
  id: null,
  login: null,
  role: null,
  stayConnected: false
};

const firebaseConfig = {
  apiKey: "AIzaSyDp2LnyPSxp6hB4eXbNtwg8YZ5Lgl41mmk",
  authDomain: "geos-fbcd2.firebaseapp.com",
  databaseURL: "https://geos-fbcd2.firebaseio.com",
  projectId: "geos-fbcd2",
  storageBucket: "geos-fbcd2.appspot.com",
  messagingSenderId: "868204829227",
  appId: "1:868204829227:web:eee4dc92c934223500e86e",
  measurementId: "G-JV21YG4DFD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();

// Initialize Performance Monitoring and get a reference to the service
/*const perf =*/ firebase.performance();

firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    // User is signed in
    const userPortal = await CustomerPortalService.getUser(user.uid);

    const userSettings: UserSettings = {
      id: user.uid,
      login: user.email,
      role: userPortal.roleCode
    };

    // only allow gpc_admin to use the application
    if (userSettings.role !== "gpc_admin") return signOut();

    //GeosStore.user = userSettings;
    //GeosStore.userParameters = userPortal.parameters;

    events.emit("login", userSettings);

    tokenFirst = await token();
    await CustomerPortalService.updateLogin(user.uid, user.emailVerified);

    /*const displayName = user.displayName;
    const email = user.email;
    const emailVerified = user.emailVerified;
    const uid = user.uid;*/
  } else {
    // User is signed out
    //GeosStore.user = null;
    events.emit("logout");
    tokenFirst = null;
  }
});

export const authenticated = () => Boolean(auth.currentUser);

/** Token generated when the user first authenticated this sessions
 * **USE token() INSTEAD IF POSSIBLE**
 */
export let tokenFirst: string;

export const token = async () =>
  auth.currentUser
    ? await auth.currentUser.getIdToken()
    : Promise.resolve<string>(null);

export const signIn = async (email: string, password: string) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    //GeosStore.setSnackbar("Login successful");
    return true;
  } catch (error) {
    /*const errorCode = error.code;
    const errorMessage = error.message;
    console.log("signIn", errorCode, errorMessage);*/
    return false;
  }
};

/*export const resetChangePassword = (
  password: string,
  token: string
): AxiosPromise<boolean> => {
  return api.put<boolean>(url + "/reset", { password: password, token: token });
};*/

export const signOut = async () => {
  // clear token remove user from local storage to log user out
  try {
    await auth.signOut();
    //GeosStore.setSnackbar("Sign-out successful");
  } catch (error) {
    // An error happened.
  }
};
