import { api } from "./Api";
import { DateToString } from "../components/Date";

const url = "/admin/sales"; // URL to web api

export const pending = () => api.get(url + "/pending").then((res) => res.data);

export const latest = (date: Date) =>
  api
    .get(url + "/latest", { params: { date: DateToString(date) } })
    .then((res) => res.data);

export interface MonthlySale {
  date: Date;
  amount: number;
  amount_paid: number;
}

export const monthly = (date: Date): Promise<MonthlySale[]> =>
  api
    .get(url + "/monthly", { params: { date: DateToString(date) } })
    .then((res) => res.data);

export const summary = (date: Date): Promise<{ nb: number; total: number }> =>
  api
    .get(url + "/summary", { params: { date: DateToString(date) } })
    .then((res) => res.data);

export interface AgeingBalance {
  currency: "USD" | "EUR";
  category: string;
  amount: number;
  total: number;
}

export const ageingBalance = (): Promise<AgeingBalance[]> =>
  api.get(url + "/ageing_balance").then((res) => res.data);

export const payments = (limit: number) =>
  api.get(url + "/payments", { params: { limit } }).then((res) => res.data);

export const exchangeRateUsdToEur = (): Promise<number> =>
  api.get<number>("/admin/exchange_rate").then((res) => +res.data);

export const dso = (): Promise<number> =>
  api.get<number>(url + "/dso").then((res) => +res.data);

export const cashSales = (): Promise<number> =>
  api.get<number>(url + "/cash_sales").then((res) => +res.data);
