import * as React from "react";
import _ from "lodash";
import { Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { distinctUntilChanged } from "rxjs/operators";

import StyledSpinner from "../components/StyledSpinner";
import NoDataFound from "../components/NoDataFound";

export enum SpinnerPreference {
  /** Only show the spinner for the first fetch */
  FirstFetch,
  /** Always show the spinner */
  Always,
  /** Never show the spinner */
  Never
}

export enum NoDataFoundPreference {
  /** Always show the "no data found" text in place of the component */
  ShowError,
  /** Never show the "no data found" text in place of the component */
  NoAction,
  /** Hide the component */
  Hide
}

function withData</** ID's data type */ T, /** Fetched data type */ U>(
  WrappedComponent: React.ComponentType<{
    data: U;
    /** ID used for the fetch */
    id?: T;
    /** Function called to force the data to be fetched again */
    fetchData?: () => Promise<void>;
  }>,
  /** Function called to fetch the data */
  fetch: (id?: T) => Promise<U>,
  {
    /** Control the spinner display during fetch */
    spinner = SpinnerPreference.Always,
    spinnerStyle = "linear",
    /** Control the display behavior when no data is found */
    noDataFound = NoDataFoundPreference.ShowError
  }: {
    spinner?: SpinnerPreference;
    spinnerStyle?: "linear" | "circular";
    noDataFound?: NoDataFoundPreference;
  } = {
    spinner: SpinnerPreference.Always,
    spinnerStyle: "linear",
    noDataFound: NoDataFoundPreference.ShowError
  }
) {
  const wrapper = (props: any) => {
    const {
      id,
      onFetch
    }: {
      id?: T;
      /** Called when the data has been fetched */
      onFetch?: (data: U) => void;
    } = props;
    const [fetching, setFetching] = React.useState(true);
    const [data, setData] = React.useState<U>(null);

    //if (!spinner) spinner = SpinnerPreference.Always;
    //if (!noDataFound) no

    const idStream = new Subject<T>();
    idStream
    .pipe(distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)))
    .pipe(
      switchMap(async value => {
        setFetching(true);
        // if fetch is expecting an argument and the id doesn't have a value, return NULL
        if ((value === undefined || value == null) && fetch.length === 1)
        return null;
        return await fetch(value);
      })
      )
      .subscribe(data => {
        setData(data);
        setFetching(false);
        if (onFetch) onFetch(data);
      });

    React.useEffect(() => {
      idStream.next(id);
    }, [id]);

    if (spinner === SpinnerPreference.Always && fetching)
      return <StyledSpinner style={spinnerStyle} />;
    if (spinner === SpinnerPreference.FirstFetch && _.isEmpty(data) && fetching)
      return <StyledSpinner style={spinnerStyle} />;

    if (_.isEmpty(data)) {
      if (noDataFound === NoDataFoundPreference.ShowError)
        return <NoDataFound />;

      if (noDataFound === NoDataFoundPreference.Hide) return <></>;
    }

    return (
      <WrappedComponent
        data={data}
        fetchData={async () => {
          setFetching(true);
          setData(await fetch(id));
          setFetching(false);
        }}
        {...props}
      />
    );
  };

  return React.memo(wrapper);
}

export default withData;
