import * as React from "react";
import Link from "@material-ui/core/Link";
import Grid, { GridJustification } from "@material-ui/core/Grid";

import * as toolsPhp from "../../services/ToolsPhp";

export enum InvoiceType {
  DubaiUSD,
  BelgiumEUR,
  BelgiumUSD,
}

const invoiceLink = ({
  id,
  justify,
  type,
}: {
  id: number;
  justify?: GridJustification;
  type: InvoiceType;
}) => (
  <Grid
    container
    direction="row"
    justify={justify || "flex-start"}
    alignItems="center"
    spacing={1}
  >
    <Link
      color="primary"
      href={toolsPhp.invoiceUrl(
        id,
        type === InvoiceType.DubaiUSD
          ? "default"
          : type === InvoiceType.BelgiumEUR
          ? "be_eur"
          : "be_usd"
      )}
      target="_blank"
    >
      <b>{id}</b>
    </Link>
  </Grid>
);

export default React.memo(invoiceLink);
