import { AxiosRequestConfig } from "axios";
import { Buffer } from "safe-buffer";
const cryptoBrowserify = require("crypto-browserify");

const bufferKey = Buffer.from("RBOAcLS4PCT81FaSKOTZ2wgCRtwHeBTC");

export default function intercept(req: AxiosRequestConfig) {
  if (!req.params) return req;

  /*let urls: string[] = [];

	//urls.push(GeosOsmService.url)
	//if (this.env && this.env.apiUrl && this.env.dir) urls.push(this.env.apiUrl);
	urls.push('http://localhost:8080/');
	console.log(message, optionalParams)

	// remove trailing /
	urls.map(url => url.replace(/\/$/, ""));

	let matchedUrl: string;
	urls.forEach(url => {
		// indexOf replacement for ES5 startsWith
		if (!matchedUrl && req.url.indexOf(url) === 0)
			matchedUrl = url;
	});

	// do not modify non-api requests
	if (!matchedUrl)
    return req;*/

  const IV_LENGTH = 16;

  const text = JSON.stringify(req.params);
  const iv = cryptoBrowserify.randomBytes(IV_LENGTH);
  const cipher = cryptoBrowserify.createCipheriv("aes-256-cbc", bufferKey, iv);
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  req.params = { a: iv.toString("hex") + ":" + encrypted.toString("hex") };

  return req;
}
