import React from "react";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import { fade, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Subject } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

import * as companies from "../../services/Companies";
import * as invoices from "../../services/Invoices";
import NoDataFound from "../NoDataFound";
import * as utils from "../../services/Utils";
import SearchResults from "./SearchResults";
import withPrivateRoute from "../../hoc/withPrivateRoute";

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  }
}));

enum DataType {
  Invoices,
  Companies
}

const searchInput = () => {
  const classes = useStyles({});
  const searchStream = new Subject<string>();
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searching, setSearching] = React.useState(false);
  const [results, setResults] = React.useState<JSX.Element>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  searchStream
    .pipe(
      debounceTime(300),
      switchMap(async value => {
        setSearching(true);
        if (utils.isNumber(value)) {
          const data = await invoices.search(value);
          return { type: DataType.Invoices, data };
        } else {
          const data = await companies.search(value);
          return { type: DataType.Companies, data };
        }
      })
    )
    .subscribe(res => {
      if (_.isEmpty(res.data)) setResults(<NoDataFound />);
      else
        setResults(
          <SearchResults
            invoicesData={res.type === DataType.Invoices ? res.data : null}
            companiesData={res.type === DataType.Companies ? res.data : null}
            onClick={() => {
              setAnchorEl(null); // close the results menu
              setSearch(""); // empty the search input
              setResults(null); // empty the results
            }}
          />
        );
      setSearching(false);
    });

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        value={search}
        placeholder={"Company or invoice…"}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ "aria-label": "Search" }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const target = event.target as HTMLInputElement;
          const value = target.value;
          setSearch(value);
          if (
            (utils.isNumber(value) && value.length < 6) ||
            (!utils.isNumber(value) && value.length < 3)
          ) {
            // quietly ignore the value
            setAnchorEl(null);
            setResults(null);
          } else {
            setAnchorEl(target);
            searchStream.next(value);
          }
        }}
      />
      <Popper
        disablePortal
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ width: 264 }}
      >
        <Paper>
          {searching && <LinearProgress />}
          {!searching && results}
        </Paper>
      </Popper>
    </div>
  );
};

const Blank = () => <></>;

export default withPrivateRoute(React.memo(searchInput), Blank);
