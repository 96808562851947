import * as React from "react";

import Time from "./Time";

export const DateToString = (value: Date) =>
  value.getFullYear() +
  "-" +
  (value.getMonth() + 1).toString().padStart(2, "0") +
  "-" +
  value
    .getDate()
    .toString()
    .padStart(2, "0");

const Date = ({
  value,
  displayTime
}: {
  value: Date;
  displayTime?: boolean;
}) => {
  const date = DateToString(value);

  if (!displayTime) return <>{date}</>;

  return (
    <>
      <b>{date}</b> <Time value={value} />
    </>
  );
};
export default React.memo(Date);
