import { api } from "./Api";

const url = "/admin/companies"; // URL to web api

export const list = ({
  search,
  onlyActive,
  tiers
}: {
  search: string;
  onlyActive: boolean;
  tiers: string;
}) => {
  if (tiers === "") tiers = null;

  return api
    .get(url, { params: { search, only_active: onlyActive.toString(), tiers } })
    .then(res => res.data);
};

export const licenses = (mainId: number) =>
  api
    .get(url + "/" + mainId + "/licenses", { params: { active: 1 } })
    .then(res => res.data);

export const expiredLicenses = (mainId: number) =>
  api
    .get(url + "/" + mainId + "/licenses", { params: { active: 0 } })
    .then(res => res.data);

export const get = (mainId: number): Promise<any> => {
  // sanity check
  if (!mainId) return Promise.resolve(null);

  return api.get(url + "/" + mainId + "/company").then(res => res.data);
};

export const accounts = (mainId: number) =>
  api.get(url + "/" + mainId + "/accounts").then(res => res.data);

export const unpaid = (mainId: number) =>
  api.get(url + "/" + mainId + "/unpaid").then(res => res.data);

export interface SearchResult {
  mainId: number;
  company: string;
}

export const search = (search: string) =>
  api
    .get<SearchResult[]>(url + "/search", { params: { search } })
    .then(res => res.data);

export const updateDetails = ({ id, data }: { id: number; data: any }) =>
  api.post(url + "/" + id + "/company", data);

export const sub = (mainId: number) =>
  api.get(url + "/" + mainId + "/sub").then(res => res.data);

export const invoices = (mainId: number) =>
  api.get(url + "/" + mainId + "/invoices").then(res => res.data);
