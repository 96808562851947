import { InvoiceType } from "../components/invoices/InvoiceLink";

export const isNumber = (value: string) => /^\d+$/.test(value);

export const primary = "#2196F3";

export const secondary = "#FF5722";

export const getInvoiceType = (
  date: Date,
  currency: "EUR" | "USD"
): InvoiceType =>
  date < new Date("2020-02-20")
    ? InvoiceType.DubaiUSD
    : currency === "EUR"
    ? InvoiceType.BelgiumEUR
    : InvoiceType.BelgiumUSD;
